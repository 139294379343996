<template>
  <div class="my-fp-comment-table">
    <!-- =========================== Filter ============================ -->
    <filter-panel
      v-model="isFilterActive"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row p-2">
        <fd-input
          v-model="filter['projectUnitFloorPlan:name']"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Floor Plan Name"
          name="filterprojectUnitFloorPlan"
          type="text"
          @keyup.native.enter="filterData()"
        ></fd-input>
      </div>
    </filter-panel>
    <!-- ========================= Table Listing ========================= -->
    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="commentTableColumns"
        :rows="commentTableData"
        :totalRows="commentTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
        @on-row-click="openCommentDetail"
      >
        <div slot="emptystate">
          <no-data message="There is no data"></no-data>
        </div>
        <div slot="table-actions" class="p-2">
          <button
            :class="{ active: isFilterActive }"
            class="btn toggle"
            @click="filterToggle"
          >
            <i class="fas fa-filter"></i>
          </button>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" @click.stop>
            <button
              class="btn main"
              v-if="status == 'Pending Approval'"
              @click="openEditModal(props.row)"
            >
              <i class="fas fa-pen"></i>
            </button>
            <button
              class="btn danger bordered ml-1"
              @click="deleteComment(props.row.id)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <!-- ============================== Modal ============================== -->
    <modal v-model="isEditModalShown">
      <edit-comment
        class="card"
        :comment="commentToEdit"
        @cancel="isEditModalShown = false"
        @submit="updateComment"
      ></edit-comment>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { setDefaultAvatar } from "@/utils/image";

export default {
  name: "MyFPCommentTable",
  components: {
    EditComment: () =>
      import("@/components/ManageFloorPlanComments/EditComment")
  },
  mixins: [vueGoodTable],
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      tab: "",

      commentTableData: [],
      commentTablePagination: {},
      commentTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "150px",
          hidden: false
        },
        {
          label: "Floor Plan",
          field: "projectUnitFloorPlan.name"
        },
        {
          label: "Comment",
          field: "content",
          tdClass: "whitespace-prewrap"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },

      isFilterActive: false,
      filter: {
        "projectUnitFloorPlan:name": ""
      },

      commentToEdit: "",
      isEditModalShown: false,

      setDefaultAvatar: setDefaultAvatar
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllComments();
    },

    openEditModal(comment) {
      this.commentToEdit = comment;
      this.isEditModalShown = true;
    },

    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllComments();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllComments();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openCommentDetail(event) {
      //
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllComments();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllComments();
    },

    // ============================== API RELATED ==============================
    async getAllComments() {
      this.$store.commit("setIsLoading", true);
      try {
        let filteredParam = {
          ...this.$cleanQueryParam(this.filter),
          status: this.status,
          self: true
        };

        let data = await this.$store.dispatch(
          "manageFloorPlanComments/getAllComments",
          {
            queries: filteredParam,
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );

        this.commentTableData = this._.cloneDeep(data.data);
        this.commentTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async updateComment(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);

        await this.$store.dispatch("manageFloorPlanComments/updateComment", {
          id: id,
          payload: payload
        });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "The comment has been updated successfully."
        });
        this.isEditModalShown = false;

        this.getAllComments();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Agent Update Comment");
        throw error;
      }
    },
    async deleteComment(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Comment",
        message: "Are you sure you want to delete this comment?",
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);

          await this.$store.dispatch(
            "manageFloorPlanComments/deleteComment",
            id
          );
          this.$notify({
            group: "alert",
            type: "success",
            title: "Comment Deleted",
            text: "The comment is successfully deleted."
          });
          this.getAllComments();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "An unexpected error occured. Please try again later."
          });
          this.$reportError(error, "Agent Delete Comment");
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.my-fp-comment-table {
}
</style>
